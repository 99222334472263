import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initMailRequiredLabel();
    this.initTalkScriptCounter();
    this.initSelectionStageCheck();
  }

  initMailRequiredLabel() {
    const el = this.element;
    const recruitmentSendMail = el.querySelector('#recruitment_send_mail');
    const mailSubject = el.querySelector('.mail_subject');
    if (el.id !== 'recruitments-form' || !recruitmentSendMail || !mailSubject) return;

    recruitmentSendMail.addEventListener('change', function () {
      if (recruitmentSendMail.checked) {
        mailSubject.classList.add('required');
      } else {
        mailSubject.classList.remove('required');
      }
    });
    recruitmentSendMail.dispatchEvent(new Event('change'));
  }

  initTalkScriptCounter() {
    const el = this.element;
    const recruitmentTalkScript = el.querySelector('#recruitment_talk_script');
    const talkScriptCounter = el.querySelector('.talk-script-counter');
    if (el.id !== 'recruitments-form' || !recruitmentTalkScript || !talkScriptCounter) return;

    recruitmentTalkScript.addEventListener('keyup', function () {
      let countNum = recruitmentTalkScript.value.length;
      let remain = 600 - countNum;
      let content =  "（600文字以内　残り " + remain + " 文字）";
      talkScriptCounter.textContent = content;
      if (remain < 0) {
        talkScriptCounter.classList.add("text-danger");
      } else {
        talkScriptCounter.classList.remove('text-danger');
      }
    });

    // Trigger the events to initialize the form
    recruitmentTalkScript.dispatchEvent(new Event('keyup'));
  }

  initSelectionStageCheck() {
    const el = this.element;
    const checkboxes = el.querySelectorAll('input[name="recruitment[selection_stage_ids][]"]');
    const checkbox5020 = el.querySelector('input[name="recruitment[selection_stage_ids][]"][value="5020"]');

    if (el.id !== 'recruitments-form' || !checkbox5020) return;

    checkbox5020.addEventListener('change', function () {
      if (this.checked) {
        checkboxes.forEach(checkbox => {
          if (checkbox !== checkbox5020) {
            checkbox.checked = false;
          }
        });
      }
    });

    checkboxes.forEach(checkbox => {
      if (checkbox !== checkbox5020) {
        checkbox.addEventListener('change', function () {
          if (this.checked) {
            checkbox5020.checked = false;
          }
        });
      }
    });
  }

  updateRecruitingCompany(event) {
    const id = event.target.value;
    const apiPath = '/recruiting_companies/';

    fetch(apiPath + id, {
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        document.getElementById('recruitment_recruiting_plan_id').value = data['recruiting_plan_id'];
        document.getElementById('recruitment_recruiting_payment_term_id').value = data['recruiting_payment_term_id'];
        document.getElementById('recruitment_refund').value = data['refund'];
      });
  }
}
